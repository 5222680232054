import "./App.css";
import Navbar from "./Components/Navbar";
import Section_2 from "./Components/Section_2";
// import Section1 from "./Components/Section_1";
import Section1 from "./Components/section1new";
import Section3 from "./Components/Section3";
import "./App.css";
import Events from "./Components/Events";
import Team from "./Components/Team";
import Footer2 from "./Components/Footer2";
// import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
// import Form2 from "./Components/Registration";
// import { useEffect } from "react";

function App() {
  return (
    <>
      <Navbar />
      <Section1 />
      <Section_2 />
      <Section3 />
      <Events />
      <Team />
      <Footer2 />
    </>
  );
}

export default App;
