import React, { useRef, useEffect, useState } from "react";
import "animate.css";
import table1 from "../Assets/images/Table1.png";
import table2 from "../Assets/images/Table2.png";
import table3 from "../Assets/images/Table3.png";
import table4 from "../Assets/images/Table4.png";
import table5 from "../Assets/images/Table5.png";
import table6 from "../Assets/images/Table6.png";
import table7 from "../Assets/images/Table7.png";
import "animate.css";

export default function Events() {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Adjust the threshold as needed
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div id="events" ref={sectionRef} style={{ overflow: "hidden" }}>
      <div
        style={{
          width: "100%",
          backgroundColor: "transparent",
          textAlign: "center",
          height: "60vw",
        }}
      >
        <div class="inner-div" style={{ position: "relative" }}>
          {/* <h1 className= "sec2-header">
            <b>EVENTS</b>
          </h1> */}
          <h1
            className="event-text-main"
            // style={{
            //   fontSize: "3vw",
            //   marginBottom: "4%",
            //   position: "absolute",
            //   top: "17vw",
            //   right: "9%",
            // }}
            // className={
            //   isVisible ? "animate__animated animate__backInRight" : ""
            // }
          >
            <b
              style={{
                fontFamily: "system-ui",
                fontWeight: "600",
              }}
            >
              EVENTS
            </b>
          </h1>
          <img
            className={`${
              isVisible ? "animate__animated animate__backInLeft" : ""
            } ev-img-1`}
            style={{
              width: "28%",
              height: "auto",
              position: "absolute",
              top: "16vw",
              left: "9%",
            }}
            src={table1}
          ></img>
          <img
            className={`${
              isVisible ? "animate__animated animate__backInLeft" : ""
            } ev-img-2`}
            style={{
              width: "20%",
              height: "auto",
              position: "absolute",
              left: "24%",
              top: "9vw",
            }}
            src={table2}
          ></img>
          <img
            className={`${
              isVisible ? "animate__animated animate__backInLeft" : ""
            } ev-img-3`}
            style={{
              width: "21%",
              height: "auto",
              position: "absolute",
              top: "31vw",
              left: "24%",
            }}
            src={table3}
          ></img>
          <img
            className={isVisible ? "animate__animated animate__fadeInUp" : ""}
            style={{
              width: "24%",
              height: "auto",
              position: "absolute",
              left: "38%",
              top: "19vw",
            }}
            src={table4}
          ></img>
          <img
            className={
              isVisible ? "animate__animated animate__backInRight" : ""
            }
            style={{
              width: "20%",
              height: "auto",
              position: "absolute",
              top: "9vw",
              right: "24%",
            }}
            src={table5}
          ></img>
          <img
            className={
              isVisible ? "animate__animated animate__backInRight" : ""
            }
            style={{
              width: "21%",
              height: "auto",
              position: "absolute",
              top: "31vw",
              right: "23%",
            }}
            src={table6}
          ></img>
          <img
            className={
              isVisible ? "animate__animated animate__backInRight" : ""
            }
            style={{
              width: "27%",
              height: "auto",
              position: "absolute",
              top: "18vw",
              right: "9%",
            }}
            src={table7}
          />
        </div>
      </div>

      {/* <div>
        <div style={{ padding: "8%", paddingTop: "15%", textAlign: "right" }}>
          
          <div class="container">
            <h1 style={{ fontSize: "3vw" }}>
              <b>Events</b>
            </h1>
            <table
              style={{
                margin: "auto",
                borderCollapse: "collapse",
                border: "1px solid black",
              }}
            >
              <tr>
                <td
                  rowSpan={2}
                  style={{ border: "1px solid black", textAlign: "right" }}
                >
                  <img
                    style={{ width: "195%", height: "auto" }}
                    src={table1}
                  ></img>
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  <img
                    style={{ width: "95%", height: "auto" }}
                    src={table2}
                  ></img>
                </td>
                <td
                  rowSpan={2}
                  style={{ border: "1px solid black", textAlign: "center" }}
                >
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={table4}
                  ></img>
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  <img
                    style={{ width: "190%", height: "auto" }}
                    src={table5}
                  ></img>
                </td>
                <td
                  rowSpan={2}
                  style={{ border: "1px solid black", textAlign: "center" }}
                >
                  <img
                    style={{ width: "180%", height: "auto" }}
                    src={table7}
                  ></img>
                </td>
              </tr>
              <tr>

                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  <img
                    style={{ width: "95%", height: "auto" }}
                    src={table3}
                  ></img>
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  <img
                    style={{ width: "189%", height: "auto" }}
                    src={table6}
                  ></img>
                </td>

              </tr>
            </table>
          </div>
        </div>
      </div> */}
    </div>
  );
}

{
  /* <h1 style={{fontSize:"3vw", marginBottom:"4%"}}><b>Events</b></h1> */
}
{
  /* <td style={{border:'1px solid black', verticalAlign:'top', textAlign:'center'}}><img style={{ width:'110%', height:'auto'}} src={table1}></img></td> */
}
{
  /* <td style={{border:'1px solid black', textAlign:'center'}}><img style={{ width:'85%', height:'auto'}} src={table7}></img></td> */
}
