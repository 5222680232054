import React, { useState } from "react";
import registerimg from "../Assets/images/registerimgnew.png";
import group14 from "../Assets/images/Group-14.png";
import { HashLink as Link } from "react-router-hash-link";

function Register() {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);

    const formData = new FormData(event.target);
    const url =
      "https://script.google.com/macros/s/AKfycbw0V71QZi3flSJpOoPc7alrtB465SsztnfN8yN2Rb5cLUyOH1uYWDX0JYJ8YtudBIyY/exec";

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Optional: Handle response data
      const data = await response.json();
      console.log("Form data sent successfully:", data);

      // Reset form after successful submission
      event.target.reset();
    } catch (error) {
      console.error("Error sending form data:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        backgroundImage: `url(${registerimg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
      }}
    >
      <div style={{ position: "relative" }}>
        <Link to="/">
          <button
            style={{
              position: "absolute",
              zIndex: "1",
              right: "12%",
              top: "6vh",
              backgroundColor: "transparent",
              border: "none",
              // fontSize: "1.7vw",
              color: "white",
              textShadow: "2px 2px 4px #000000",
            }}
          >
            HOME
          </button>
        </Link>
        <div className=" text-center" style={{ position: "relative" }}>
          <Link to="/">
            <img
              className="in-form-img"
              src={group14}
              height="auto"
              alt="Header logo"
            />
          </Link>

          <div className="row">
            <div className="colnew">
              <div style={{ marginTop: "30px" }}>
                <h1 style={{ textShadow: "2px 2px 4px #000000" }}>
                  REGISTRATION
                </h1>
              </div>
              <div>
                <p
                  style={{
                    color: "#2DB9CA",
                    textShadow: "2px 2px 4px #000000",
                    marginBottom: "0",
                  }}
                >
                  Register with us, and be a part of our community
                </p>
              </div>
              <form onSubmit={handleSubmit} name="contact-form">
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    padding: "15px",
                  }}
                >
                  <div className="mb-3 form-border">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      name="First-Name"
                      required
                    />
                  </div>
                  <div class="mb-3  form-border">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="Last Name"
                      name="Last-Name"
                      required
                    />
                  </div>
                  <div class="mb-3 form-border mobile-input-resp">
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="Email ID"
                      name="Email-Id"
                      required
                    />
                  </div>
                  <div class="mb-3 form-border">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="Mobile No."
                      name="Mobile-No"
                      required
                    />
                  </div>
                  <div class="mb-3 form-border">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="City"
                      name="City"
                      required
                    />
                  </div>
                  <div class="mb-3 form-border">
                    {/* <input
                      type="text"
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="State"
                      name="State"
                    /> */}
                    <select
                      class="form-control"
                      id="country-state"
                      name="State"
                      style={{ fontSize: "13px", color: "grey" }}
                      required
                    >
                      <option value="">Select state ▼</option>
                      <option value="AN">Andaman and Nicobar Islands</option>
                      <option value="AP">Andhra Pradesh</option>
                      <option value="AR">Arunachal Pradesh</option>
                      <option value="AS">Assam</option>
                      <option value="BR">Bihar</option>
                      <option value="CH">Chandigarh</option>
                      <option value="CT">Chhattisgarh</option>
                      <option value="DN">Dadra and Nagar Haveli</option>
                      <option value="DD">Daman and Diu</option>
                      <option value="DL">Delhi</option>
                      <option value="GA">Goa</option>
                      <option value="GJ">Gujarat</option>
                      <option value="HR">Haryana</option>
                      <option value="HP">Himachal Pradesh</option>
                      <option value="JK">Jammu and Kashmir</option>
                      <option value="JH">Jharkhand</option>
                      <option value="KA">Karnataka</option>
                      <option value="KL">Kerala</option>
                      <option value="LA">Ladakh</option>
                      <option value="LD">Lakshadweep</option>
                      <option value="MP">Madhya Pradesh</option>
                      <option value="MH">Maharashtra</option>
                      <option value="MN">Manipur</option>
                      <option value="ML">Meghalaya</option>
                      <option value="MZ">Mizoram</option>
                      <option value="NL">Nagaland</option>
                      <option value="OR">Odisha</option>
                      <option value="PY">Puducherry</option>
                      <option value="PB">Punjab</option>
                      <option value="RJ">Rajasthan</option>
                      <option value="SK">Sikkim</option>
                      <option value="TN">Tamil Nadu</option>
                      <option value="TG">Telangana</option>
                      <option value="TR">Tripura</option>
                      <option value="UP">Uttar Pradesh</option>
                      <option value="UT">Uttarakhand</option>
                      <option value="WB">West Bengal</option>
                    </select>
                  </div>
                  <div class="mb-3 form-border">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="Match"
                      name="Match"
                      required
                    />
                  </div>
                  <div class="mb-3 form-border">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="Gotra"
                      name="Gotra"
                      required
                    />
                  </div>
                  <div>
                    <label
                      style={{
                        margin: "10px",
                        // fontSize: "0.8vw",
                        textShadow: "2px 2px 4px #000000",
                      }}
                      htmlFor="form-select"
                    >
                      PLAYER DETAILS
                    </label>
                    <select
                      name="Player-Details"
                      class="form-select"
                      aria-label="Default select example"
                      required
                      style={{
                        marginBottom: "20px",
                        boxShadow: "2px 2px 4px #000000",
                      }}
                    >
                      <option required selected>
                        Choose Your Profile
                      </option>
                      <option required value="Option A">
                        All Rounder
                      </option>
                      <option required value="Option B">
                        Bowler
                      </option>
                      <option required value="Option C">
                        Batsman
                      </option>
                    </select>
                  </div>
                </div>
                <input
                  type="submit"
                  value={submitting ? "Submitting..." : "Submit"}
                  className="btn btn-primary submit-button-form"
                  // style={{
                  //   backgroundColor: "rgb(45, 185, 202)",
                  //   width: "10vw",
                  //   padding: "10px",
                  //   marginBottom: "30px",
                  //   border: "none",
                  //   boxShadow: "2px 2px 4px #000000",
                  // }}
                  disabled={submitting}
                />
              </form>
            </div>
          </div>
        </div>
      </div>

      <footer
        className="footerline"
        style={{
          position: "fixed",
          zIndex: "1",
          bottom: "15px",
          width: "100%",
        }}
      >
        <p
          className="box-3-head contact-container last-line"
          style={{ marginLeft: "20px", marginRight: "20px" }}
        >
          Copyright © 2024 - 2025 GSB Sports & Charitable Trust all rights
          reserved
        </p>
        <p
          className="box-3-head contact-container last-line"
          style={{ marginLeft: "20px", marginRight: "20px" }}
        >
          Website done by{" "}
          <span
            className="last-line"
            style={{
              color: "#2DB9CA",
              display: "contents",
              textShadow: "2px 2px 4px #000000",
            }}
          >
            ThryvTechLabs
          </span>
        </p>
      </footer>
    </div>
  );
}

export default Register;
