import React, { useState, useEffect, useRef } from "react";
import "animate.css";

function Section3() {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Adjust the threshold as needed
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div
      id="what-we-do"
      ref={sectionRef}
      className="sec3-container"
      style={{ overflow: "hidden" }}
    >
      {/* <img className="section-img" src={image3} alt="hiker_image" /> */}
      <div className="sec3-main-div">
        <h1
          className={`${
            isVisible ? "animate__animated animate__backInRight" : ""
          } sec3-text-head`}
        >
          <b
            style={{
              fontFamily: "system-ui",
              fontWeight: "600",
            }}
          >
            OUR MISSION
          </b>
        </h1>
        <p
          className={`${
            isVisible ? "animate__animated animate__backInRight" : ""
          } sec2-text demo-class`}
        >
          The Goa Sports & Charity trust is committed to fostering sportsmanship
          and community welfare in Goa. Through organizing sporting events, the
          trust encourages participation in sports and prommotes physical
          fitness, teamwork, and healthy competition among participants. By
          combining sports and charity, the trust strives to make a positive
          impact on the lives of the individuals and communities in Goa.
        </p>
      </div>
    </div>
  );
}

export default Section3;
