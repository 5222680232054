import React, { useState, useRef, useEffect } from "react";
import "animate.css";
import sectionbackground from "../Assets/images/section2img.svg";

export default function Section_2() {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);

  const toggleContentVisibility = () => {
    setIsContentVisible(!isContentVisible);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Adjust the threshold as needed
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div id="about-us" ref={sectionRef} className="sec2-main-div">
      <img
        className="section-img"
        src={sectionbackground}
        alt="back_linear-gradient_image"
      />
      <div style={{ padding: "8%", textAlign: "right" }}>
        <h1
          className={`${
            isVisible ? "animate__animated animate__backInLeft" : ""
          } sec3-text-head`}
        >
          <b
            style={{
              fontFamily: "system-ui",
              fontWeight: "600",
            }}
          >
            WHO WE ARE
          </b>
        </h1>
        <p
          className={`${
            isVisible ? "animate__animated animate__backInLeft" : ""
          } sec2-text `}
        >
          The Saraswat Brahmins have a rich and varied history, mentioned in
          ancient texts like the Vedas and Puranas. They may have originated
          from Central Asia or been indigenous to the Indian subcontinent. The
          team 'Saraswat' may refer to offspring of the goddess Saraswati or
          residents of the Saraswati river basn. Over time, they migarted to
          various regions, including Goa
          {isContentVisible ? (
            <span className="sec2-text">
              , Karnataka, and Kerala, facing challenges such as persecution by
              the Portuguese. Despite these difficulties, they preserved their
              culture, language, and traditions. The cuommunity has made
              significant contirbutions to society, including in governance,
              banking, and eductaion. They continue to celebrate their heritage
              through events like the Sarsangan GSB Cricket Tournaments,
              Fastering bands within the community.
            </span>
          ) : null}
          <button
            style={{
              border: "none",
              color: "rgb(228, 44, 36)",
              backgroundColor: "transparent",
              borderRadius: "23px",
            }}
            onClick={toggleContentVisibility}
            id="myBtn"
          >
            {isContentVisible ? "View less" : "View more"}
          </button>
        </p>
      </div>
    </div>
  );
}
