import React, { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import image1 from "../Assets/images/image-1.png";
import image2 from "../Assets/images/image-2.png";
import image3 from "../Assets/images/image-3.png";
import group14 from "../Assets/images/Group-14.png";
import sticky from "../Assets/images/stickyarrow.svg";
import bannerpic1 from "../Assets/images/bannerpic.png";
import bannerpic2 from "../Assets/images/bannerpic2.png";
import bannerpic3 from "../Assets/images/bannerpic3.png";

export default function Section1() {
  const [slideIndex, setSlideIndex] = useState(0);
  const [showScrollButton, setShowScrollButton] = useState(true);
  const [bannerText, setBannerText] = useState("");
  const [bannerImage, setBannerImage] = useState("");

  useEffect(() => {
    // Set initial banner text and image
    setBannerText(
      "Empowering Hope,<br/> Inspiring Change:<br/> Designing A Brighter<br/> Tomorrow"
    );
    setBannerImage(bannerpic1);

    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex + 1) % 3); // Assuming you have 3 slides
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Set banner text and image based on slideIndex
    switch (slideIndex) {
      case 0:
        setBannerText(
          "Empowering Hope,<br/> Inspiring Change: <br/>Designing A Brighter <br/>Tomorrow"
        );
        setBannerImage(bannerpic1);
        break;
      case 1:
        setBannerText(
          "Upholding Tradition, <br/>Embracing Innovation: <br/> Designing the Future Together"
        );
        setBannerImage(bannerpic3);
        break;
      case 2:
        setBannerText(
          "Strength In Unity,<br/> Bound By The Game: <br/> Let's Unite On The Field"
        );
        setBannerImage(bannerpic2);
        break;
      default:
        break;
    }
  }, [slideIndex]);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrolled = window.scrollY;

    if (documentHeight - windowHeight === scrolled) {
      setShowScrollButton(false);
    } else {
      setShowScrollButton(true);
    }
  };
  useEffect(() => {
    // Add event listener to track scroll position
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Remove event listener when component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToFooter = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const slides = [image1, image2, image3];

  return (
    <div style={{ position: "relative" }}>
      <div className="text-center" style={{ position: "relative" }}>
        <Link to="/">
          <img
            className="in-img"
            src={group14}
            height="auto"
            alt="Header logo"
          />
        </Link>
        <img
          className="back-main-img"
          src={slides[slideIndex]}
          alt="Background"
        />
        <img src={bannerImage} className="in-banner-img" alt="" />
        <div className="row">
          <div className="col">
            <p
              className="in-text"
              dangerouslySetInnerHTML={{ __html: bannerText }}
            />
            {/* {showScrollButton && (
              <img
                className="scrollmain"
                src={sticky}
                alt="Scrolling-arrow"
                onClick={scrollToFooter}
              />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}
