import React from "react";
import group14 from "../Assets/images/Group-14.png";
import facebook from "../Assets/images/Facebook.svg";
import linkedin from "../Assets/images/Linkedin.svg";
import twitter from "../Assets/images/twitter.svg";
import youtube from "../Assets/images/youtube.svg";
import instagram from "../Assets/images/Instagram.svg";
import footerback2 from "../Assets/images/footerback2.png";
import phonecall1 from "../Assets/images/phonecall1.svg";
import mail from "../Assets/images/mail.svg";
import { HashLink as Link } from "react-router-hash-link";
import sticky from "../Assets/images/stickyarrow.svg";

function Footer2() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div id="contact-us" className="footerhead">
      <img
        className="scrollmain"
        src={sticky}
        alt="Scrolling-arrow"
        onClick={scrollToTop}
      />
      <img
        src={footerback2}
        alt="hiker_image"
        className="footerimg"
        style={{ height: "100%" }}
      />
      <div
        style={{
          paddingTop: "3%",
          paddingBottom: "3%",
        }}
      >
        <div
          class="contact-container"
          style={{
            position: "relative",
            paddingBottom: "1%",
          }}
        >
          <div class="contact-box-1">
            <img
              class="last-img"
              src={group14}
              alt="company=logo"
              style={{ width: "45%" }}
            />
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <a href="#" target="_blank">
                <img
                  class="socialmedia-icons"
                  src={facebook}
                  alt="facebook-icon"
                />
              </a>
              <a href="#" target="_blank">
                <img
                  class="socialmedia-icons"
                  src={twitter}
                  alt="linkedin-icon"
                />
              </a>
              <a href="#" target="_blank">
                <img
                  class="socialmedia-icons"
                  src={linkedin}
                  alt="Insta-icon"
                />
              </a>
              <a href="#" target="_blank">
                <img
                  class="socialmedia-icons"
                  src={instagram}
                  alt="Insta-icon"
                />
              </a>
              <a href="#" target="_blank">
                <img class="socialmedia-icons" src={youtube} alt="Insta-icon" />
              </a>
            </div>
          </div>
          <div class="contact-box-3">
            <p class="box-3-head" style={{ fontWeight: "bold" }}>
              GSB sports Charitable Trust
            </p>
            <div className="footer-inner-div">
              <p class="box-3-head">
                <Link
                  to="#about-us"
                  smooth
                  style={{
                    color: "white",
                    textDecoration: "none",
                    marginRight: "20px",
                  }}
                >
                  About Us
                </Link>
              </p>
              <p class="box-3-head">
                <Link
                  to="#what-we-do"
                  smooth
                  style={{
                    color: "white",
                    textDecoration: "none",
                    marginRight: "20px",
                  }}
                >
                  What we do
                </Link>
              </p>
              <p class="box-3-head">
                <Link
                  to="#events"
                  smooth
                  style={{
                    color: "white",
                    textDecoration: "none",
                    marginRight: "20px",
                  }}
                >
                  Events
                </Link>
              </p>
              <p class="box-3-head">
                <Link
                  to="#our-team"
                  smooth
                  style={{
                    color: "white",
                    textDecoration: "none",
                    marginRight: "20px",
                  }}
                >
                  Our team
                </Link>
              </p>
            </div>
          </div>
          <div class="contact-box-4">
            <p class="box-3-head" style={{ fontWeight: "bold" }}>
              Contact
            </p>
            <div className="footer-inner-div">
              <p class="box-3-contact">
                <img
                  class="socialmedia-icons"
                  style={{ marginRight: "5px" }}
                  src={phonecall1}
                  alt="call-icon"
                />
                <a
                  href="tell:+91 7858967818"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  +91 7858967818
                </a>
              </p>
              <p class="box-3-contact">
                <img
                  class="socialmedia-icons"
                  style={{ marginRight: "5px" }}
                  src={mail}
                  alt="mail-icon"
                />
                <a
                  href="mailto:support@gsb.com"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  support@gsb.com
                </a>
              </p>
              <p class="box-3-contact">
                <img
                  class="socialmedia-icons"
                  style={{ marginRight: "5px" }}
                  src={mail}
                  alt="mail-icon"
                />
                <a
                  href="mailto:enquiries@gsb.com"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  enquiries@gsb.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <hr
          style={{
            color: "#2DB9CA",
            width: "80%",
            margin: "auto",
            position: "relative",
          }}
        />

        <footer className="footerline">
          <p
            class="box-3-head contact-container last-line"
            style={{ marginRight: "20px", marginLeft: "20px" }}
          >
            Copyright © 2024 - 2025 GSB Sports & Charitable Trust all rights
            reserved
          </p>
          <p
            class="box-3-head contact-container"
            style={{ marginRight: "20px", marginLeft: "20px" }}
          >
            Website done by{" "}
            <span style={{ color: "#2DB9CA", display: "contents" }}>
              ThryvTechLabs
            </span>
          </p>
        </footer>
      </div>
    </div>
  );
}

export default Footer2;
