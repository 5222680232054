import React, { useEffect, useState, useRef } from "react";
import "animate.css";
import director1 from "../Assets/images/director1.png";
import director2 from "../Assets/images/director2.png";
import director3 from "../Assets/images/director3.png";
import director4 from "../Assets/images/director4.png";
import circle1 from "../Assets/images/circle1.svg";
// import circle2 from "../Assets/images/circle2.svg";
import circle3 from "../Assets/images/circle3.svg";
import circle4 from "../Assets/images/circle4.svg";
import circle5 from "../Assets/images/circle5.svg";
import teams2 from "../Assets/images/teams2.svg";
import scroll from "../Assets/images/scroll.svg";
import backimg from "../Assets/images/teambackimg.svg";
import directorimg1 from "../Assets/images/VikramG.png";
import directorimg2 from "../Assets/images/LaxmanG_Treasurer.png";
import directorimg3 from "../Assets/images/PranavB.png";
import directorimg4 from "../Assets/images/kapil.png";
import directorimg5 from "../Assets/images/kapilA.png";
import directorimg6 from "../Assets/images/KetanK.png";
import directorimg7 from "../Assets/images/SachinS.png";
import directorimg8 from "../Assets/images/SidhithR_President.png";
import directorimg9 from "../Assets/images/TanmayU.png";
import directorimg10 from "../Assets/images/VenkatB_Secretary.png";
import directorimg11 from "../Assets/images/BalkrishnaM.png";

function Team() {
  const slideIndex = [1, 1];
  const slideId = ["mySlides-tablet"];

  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Adjust the threshold as needed
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const showSlides = (n, no) => {
    let x = document.getElementsByClassName(slideId[no]);
    console.log("x:", x); // Debugging: Log x to see its value
    if (!x || x.length === 0) return; // Added check to prevent errors if x is undefined or empty
    if (n > x.length) {
      slideIndex[no] = 1;
    }
    if (n < 1) {
      slideIndex[no] = x.length;
    }
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    x[slideIndex[no] - 1].style.display = "block";
  };

  useEffect(() => {
    showSlides(1, 0);
    showSlides(1, 1);
  }, []); // Call showSlides on component mount

  const plusSlides = (n, no) => {
    showSlides((slideIndex[no] += n), no);
  };

  return (
    <div
      id="our-team"
      ref={sectionRef}
      className="team-div-main"
      style={{ overflow: "hidden" }}
    >
      <img
        className="section-img"
        src={backimg}
        style={{ position: "absolute", width: "100%" }}
      />
      <h1
        className={`${
          isVisible ? "animate__animated animate__backInLeft" : ""
        } teams-text`}
      >
        <b style={{ fontWeight: "600" }}>MEET OUR TEAM</b>
      </h1>

      <div className="team-first-div">
        <div className="slideshow-container" id="desktopDiv">
          <div
            className="mySlides1"
            style={{ textAlign: "center", display: "block" }}
          >
            <img src={directorimg8} alt="Mountains" className="team-img" />
            <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-circle`}
              src={circle3}
              alt="background-circle"
            />
            <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-circle-2`}
              src={circle5}
              alt="background-circle"
            />
            <img src={directorimg10} alt="Mountains" className="team-img" />
            <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-circle-3`}
              src={circle4}
              alt="background-circle"
            />
            <img src={directorimg2} alt="Mountains" className="team-img" />
            {/* <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-circle-2`}
              src={circle5}
              alt="background-circle"
            /> */}
            <div
              className="text-cont"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2vw",
                marginBottom: "2vw",
              }}
            >
              <p>
                <b>Sidhit Raiturkar</b> <br />
                President
              </p>
              <p>
                <b>Venkat Biche</b> <br />
                Secretary
              </p>
              <p>
                <b>Laxman Ghanekar</b> <br />
                Treasurer
              </p>
            </div>
          </div>

          {/* image row 2  */}
          <div
            className="mySlides2"
            style={{ textAlign: "center", display: "block" }}
          >
            <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-circle-4`}
              src={circle5}
              alt="background-circle"
            />
            <img src={directorimg4} alt="Mountains" className="team-img" />
            <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-circle-5`}
              src={circle3}
              alt="background-circle"
            />
            <img src={directorimg1} alt="Mountains" className="team-img" />
            <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-circle-6`}
              src={circle4}
              alt="background-circle"
            />
            <img src={directorimg6} alt="Mountains" className="team-img" />
            <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-circle-7`}
              src={circle5}
              alt="background-circle"
            />
            <img src={directorimg3} alt="Mountains" className="team-img" />
            <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-circle-8`}
              src={circle3}
              alt="background-circle"
            />
            <img src={directorimg5} alt="Mountains" className="team-img" />
            <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-circle-9`}
              src={circle4}
              alt="background-circle"
            />
            <img src={directorimg9} alt="Mountains" className="team-img" />
            <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-circle-10`}
              src={circle5}
              alt="background-circle"
            />
            <img src={directorimg7} alt="Mountains" className="team-img" />
            <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-circle-11`}
              src={circle3}
              alt="background-circle"
            />
            <img src={directorimg11} alt="Mountains" className="team-img" />

            <div
              className="text-content-new"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "1.5vw",
              }}
            >
              <p style={{ marginLeft: "-2vw" }}>
                <b>Kapil Sinai Borkar</b> <br />
                Trustee
              </p>
              <p style={{ marginLeft: "-1vw" }}>
                <b>Vikram Gaitonde</b> <br />
                Trustee
              </p>
              <p style={{ marginLeft: "1vw" }}>
                <b>Ketan Kunde</b> <br />
                Trustee
              </p>
              <p style={{ marginRight: "2vw" }}>
                <b>Pranav Biche</b> <br />
                Trustee
              </p>
              <p style={{ marginLeft: "-0.3vw" }}>
                <b>Kapil Angle</b> <br />
                Trustee
              </p>
              <p>
                <b>Tanmay Usgaonkar</b> <br />
                Trustee
              </p>
              <p style={{ marginRight: "2vw" }}>
                <b>Sachin Sardessai</b> <br />
                Trustee
              </p>
              <p style={{ marginLeft: "-2vw" }}>
                <b>Balkrishna</b> <br />
                Trustee
              </p>
            </div>
          </div>
        </div>

        {/* tablet view container */}
        <div className="tablet-slideshow-container">
          <div className="mySlides-tablet" style={{ textAlign: "center" }}>
            <img src={directorimg8} alt="Mountains" className="team-img" />
            {/* <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-circle-10`}
              src={circle5}
              alt="background-circle"
            /> */}
            <img src={directorimg10} alt="Mountains" className="team-img" />
            <img src={directorimg2} alt="Mountains" className="team-img" />

            <div
              className="text-cont"
              style={{
                marginTop: "1.5vw",
                display: "flex",
                justifyContent: "space-between",
                width: "66%",
              }}
            >
              <p>
                <b>Sidhit Raiturkar</b> <br />
                President
              </p>
              <p>
                <b>Venkat Biche</b> <br />
                Secretary
              </p>
              <p>
                <b>Laxman Ghanekar</b> <br />
                Treasurer
              </p>
            </div>
          </div>
          <div className="mySlides-tablet" style={{ textAlign: "center" }}>
            <img src={directorimg4} alt="Mountains" className="team-img" />
            <img src={directorimg1} alt="Mountains" className="team-img" />
            <img src={directorimg6} alt="Mountains" className="team-img" />
            <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-cir-dub`}
              src={circle3}
              alt="background-circle"
            />
            <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              }  team-cir-dub-2`}
              src={circle4}
              alt="background-circle"
            />
            <div
              className="text-cont"
              style={{
                marginTop: "1.5vw",
                display: "flex",
                justifyContent: "space-between",
                width: "58%",
              }}
            >
              <p>
                <b>Kapil Borkar</b> <br />
                Trustee
              </p>
              <p>
                <b>Vikram Gaitonde</b> <br />
                Trustee
              </p>
              <p>
                <b>Ketan Kunde</b> <br />
                Trustee
              </p>
            </div>
          </div>

          <div className="mySlides-tablet" style={{ textAlign: "center" }}>
            <img src={directorimg3} alt="Mountains" className="team-img" />
            <img src={directorimg5} alt="Mountains" className="team-img" />
            <img src={directorimg11} alt="Mountains" className="team-img" />
            <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-cir-dub`}
              src={circle3}
              alt="background-circle"
            />
            <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-cir-dub-2`}
              src={circle5}
              alt="background-circle"
            />
            <div
              className="text-cont"
              style={{
                marginTop: "1.5vw",
                display: "flex",
                justifyContent: "space-between",
                width: "58%",
              }}
            >
              <p>
                <b>Pranav Biche</b> <br />
                Trustee
              </p>
              <p style={{ marginRight: "2vw" }}>
                <b>Kapil Angle</b> <br />
                Trustee
              </p>
              <p style={{ marginRight: "2vw" }}>
                <b>Balkrishna</b> <br />
                Trustee
              </p>
            </div>
          </div>
          <div className="mySlides-tablet" style={{ textAlign: "center" }}>
            <img src={directorimg9} alt="Mountains" className="team-img" />
            <img src={directorimg7} alt="Mountains" className="team-img" />
            <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-cir-dub`}
              src={circle3}
              alt="background-circle"
            />
            <img
              className={`${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              } team-cir-dub-2`}
              src={circle5}
              alt="background-circle"
            />
            <div
              className="text-cont"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "42%",
                fontSize: "2.3vw",
              }}
            >
              <p>
                <b>Tanmay Usgaonkar</b> <br />
                Trustee
              </p>
              <p>
                <b>Sachin Sardessai</b> <br />
                Trustee
              </p>
            </div>
          </div>
          {/* last line */}
          <div className="scroll-container">
            {/* <button className="prev" onClick={() => plusSlides(-1, 0)}>
              &#10094;
            </button> */}
            {/* <button className="next" onClick={() => plusSlides(1, 0)}> */}
            <img
              className="next"
              onClick={() => plusSlides(1, 0)}
              src={scroll}
              alt="scroll-bar"
            />
            {/* </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
