// With google translate

import React, { useState, useEffect, useRef } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "animate.css";

export default function Navbar() {
  const [showMenu, setShowMenu] = useState(false);
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);

  const toggleContentVisibility = () => {
    setIsContentVisible(!isContentVisible);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Adjust the threshold as needed
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    // Load Google Translate script dynamically
    const script = document.createElement("script");
    script.src =
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    document.body.appendChild(script);

    // Function to initialize Google Translate element
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        { pageLanguage: "en" },
        "google_translate_element"
      );
    };

    return () => {
      // Cleanup function to remove script when component unmounts
      document.body.removeChild(script);
      delete window.googleTranslateElementInit;
    };
  }, []);

  return (
    <nav ref={sectionRef} className="navbar navbar-expand-lg nav-resp">
      {/* comment */}
      <div className="container-fluid">
        <Link
          to="/form"
          target="_blank"
          smooth
          style={{ textDecoration: "none" }}
          onClick={() => [setShowMenu(false)]}
        >
          <button
            style={{
              border: "none",
              backgroundColor: "rgb(228 44 36)",
              borderRadius: "4px",
            }}
            className="outer-register-button"
          >
            <a
              className="nav-link resp outer-register-button"
              href="/form"
              target="_blank"
              style={{ color: "white", fontSize: "12px" }}
            >
              REGISTER
            </a>
          </button>
        </Link>
        <button
          style={{ borderColor: "white", border: "none" }}
          className="navbar-toggler d-lg-none"
          type="button"
          onClick={toggleMenu}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`${
            isVisible
              ? "animate__animated animate__bounceInRight animate__Fast"
              : ""
          } collapse navbar-collapse  ${showMenu ? "show" : ""} super`}
          id="navbarNavDropdown"
        >
          <h1 className="mobile-nav-text">WELCOME</h1>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item" style={{ textAlign: "center" }}>
              <Link
                to="#about-us"
                smooth
                style={{ textDecoration: "none" }}
                onClick={() => {
                  setShowMenu(false);
                }}
              >
                <a className="nav-link resp" href="#section-2">
                  ABOUT US
                </a>
              </Link>
            </li>
            <li className="nav-item" style={{ textAlign: "center" }}>
              <Link
                to="#what-we-do"
                smooth
                style={{ textDecoration: "none" }}
                onClick={() => {
                  setShowMenu(false);
                }}
              >
                <a className="nav-link resp" href="#what-we-do">
                  WHAT WE DO
                </a>
              </Link>
            </li>
            <li className="nav-item" style={{ textAlign: "center" }}>
              <Link
                to="#events"
                smooth
                style={{ textDecoration: "none" }}
                onClick={() => {
                  setShowMenu(false);
                }}
              >
                <a className="nav-link resp" href="#events">
                  EVENTS
                </a>
              </Link>
            </li>
            <li className="nav-item" style={{ textAlign: "center" }}>
              <Link
                to="#our-team"
                smooth
                style={{ textDecoration: "none" }}
                onClick={() => {
                  setShowMenu(false);
                }}
              >
                <a className="nav-link resp" href="#team">
                  OUR TEAM
                </a>
              </Link>
            </li>
            <li className="nav-item" style={{ textAlign: "center" }}>
              <Link
                to="#contact-us"
                smooth
                style={{ textDecoration: "none" }}
                onClick={() => [setShowMenu(false)]}
              >
                <a className="nav-link resp" href="#footer">
                  CONTACT
                </a>
              </Link>
            </li>
            <li className="nav-items-unique">
              <Link
                to="/form"
                target="_blank"
                smooth
                style={{ textDecoration: "none" }}
                onClick={() => [setShowMenu(false)]}
              >
                <button
                  style={{
                    border: "none",
                    backgroundColor: "rgb(228 44 36)",
                    borderRadius: "8px",
                  }}
                >
                  <a
                    className="nav-link resp"
                    href="/form"
                    target="_blank"
                    style={{
                      color: "white",
                      marginTop: "-1px",
                      marginBottom: "-1px",
                      fontWeight: "400",
                    }}
                  >
                    REGISTER
                  </a>
                </button>
              </Link>
            </li>
            {/* Add Google Translate button */}
            <li className="nav-item" style={{ textAlign: "center" }}>
              <div id="google_translate_element"></div>
            </li>
          </ul>
          <p
            // style={{ color: "black", fontSize: "15px", marginTop: "60px" }}
            className="mobile-nav-2-text"
          >
            Webiste done by{" "}
            <span style={{ color: "rgb(45, 185, 202)" }}>ThryvTechLabs</span>
          </p>
        </div>
      </div>
      {/* <TranslateComponent /> */}
    </nav>
  );
}
